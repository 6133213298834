import Vue from 'vue'
// import store from '../vuex/store'
import i18n from '@/i18n/i18n.js'
// import AxiosPlugin from '../axios/axios.js'
// Vue.use(AxiosPlugin, '$axios');
// import HYConfig from '../config/config.js'
// Vue.use(HYConfig)
import {
	Message,
} from 'element-ui';

// import {
// 	MessageBox,
// } from 'element-ui';

// Vue.prototype.$confirm = MessageBox.confirm;

/*工具库*/
const Utils = {
	//默认分页全部数据
	defaultPagination() {
		return {
			current_page: 1,
			page_sizes: [5, 10, 20, 30, 50, 100, 200, 300],
			page_size: 20,
			total: 0
		}
	},
	//默认可分页，选择
	pageSizes: [5, 10, 20, 30, 50, 100, 200, 300],
	//默认分页
	defaultPageSize: 20,
	//一件代发默认分页
	DsPageSize: 200,
	//一件代发最大分页
	DsMaxPageSize: 500,
	//一件代发明细
	pageSizes2: [300, 500, 600, 1000],

	//计算分页查询数据
	// calcPagination(pagination) {
	// 	let offset = (pagination.current_page - 1) * pagination.page_size;
	// 	let limit = pagination.page_size;
	// 	if(pagination.total<=offset){
	// 		offset = pagination.current_page * pagination.page_size;
	// 	}
	// 	return {
	// 		offset: offset,
	// 		limit: limit
	// 	}
	// },

	//table索引
	tableIndex(page) {
		if (page) {
			return (page.current_page - 1) * page.page_size + 1;
		}
	},
	//table索引
	tableIndex2(page, index) {
		if (page) {
			return (page.current_page - 1) * page.page_size + 1 + index;
		}
	},
	//i18n转换
	i18nText(TEXT_CN, TEXT_EN) {
		// let locale = i18n._vm.locale;
		if ('en' == i18n._vm.locale && !!TEXT_EN) {
			return TEXT_EN;
		} else {
			return TEXT_CN;
		}
	},
	//i18n转换
	i18nKeyText(item, key) {
		if ('en' == i18n._vm.locale && !!item[key + 'En']) {
			return item[key + 'En'];
		} else {
			return item[key];
		}
	},
	//i18n数据字典转换
	i18nCodeText(item) {
		// let locale = i18n._vm.locale;
		if ('en' == i18n._vm.locale && !!item.codeTextEn) {
			return item.codeTextEn;
		} else {
			return item.codeText;
		}
	},
	//过滤数组对象，返回图片数组
	// filterArrayImgList(list,key){
	// 	return list.filter(item=>{
	// 		return /\.(jpe?g|gif|bmp|ico|tiff|png|webp)$/i.test(item[key]);
	// 	}).map(item=>{
	// 		return item[key]
	// 	})
	// },
	//kg转lbs,千克转磅
	kgToLbsFixed2(val) {
		if (val && !isNaN(parseFloat(val))) {
			return (parseFloat(val) * 2.2046226).toFixed(2);
		} else {
			return '';
		}
	},
	//cm转in,厘米转英寸
	cmToInFixed2(val) {
		if (val && !isNaN(parseFloat(val))) {
			return (parseFloat(val) * 0.3937008).toFixed(2);
		} else {
			return '';
		}
	},
	// 日期格式化
	fomatterDate_YYYY: (dateStr) => {
		let date = new Date(dateStr);
		let Y = date.getFullYear() + '';
		// h = date.getHours() + ':',
		// m = date.getMinutes() + ':',
		// s = date.getSeconds();
		return Y;
	},
	// 日期格式化
	fomatterDate_MMddYYYY: (dateStr) => {
		let date = new Date(dateStr);
		let Y = date.getFullYear() + '',
			M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '',
			D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate() + '';
		// h = date.getHours() + ':',
		// m = date.getMinutes() + ':',
		// s = date.getSeconds();
		return M + D + Y;
	},
	// 日期格式化
	fomatterDate_YYYYMMdd: (dateStr) => {
		let date = new Date(dateStr);
		let Y = date.getFullYear() + '-',
			M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-',
			D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
		// h = date.getHours() + ':',
		// m = date.getMinutes() + ':',
		// s = date.getSeconds();
		return Y + M + D;
	},
	// 日期格式化-无分割线
	fomatterDate_YYYYMMdd2: (dateStr) => {
		let date = new Date(dateStr);
		let Y = date.getFullYear(),
			M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1),
			D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
		// h = date.getHours() + ':',
		// m = date.getMinutes() + ':',
		// s = date.getSeconds();
		return Y + M + D;
	},
	// 日期格式化2
	fomatterDate_YYYYMM: (dateStr) => {
		let date = new Date(dateStr);
		let Y = date.getFullYear() + '-',
			M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
		// h = date.getHours() + ':',
		// m = date.getMinutes() + ':',
		// s = date.getSeconds();
		return Y + M;
	},
	// 日期格式化3
	fomatterDate_YYYYMMddhms: (dateStr) => {
		let date = new Date(dateStr);
		let Y = date.getFullYear() + '-',
			M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-',
			D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate(),
			h = date.getHours() < 10 ? '0' + date.getHours() : date.getHours(),
			m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes(),
			s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
		return Y + M + D + " " + h + ":" + m + ":" + s;
	},
	//格式化数字
	FormatNumber(val, type) {
		if (!type) {
			let re_val = parseFloat(val);
			if (!isNaN(re_val)) {
				return re_val;
			} else {
				return val;
			}
		}
		return val;
	},
	//格式化数字
	FormatNumberThousand(val, type) {
		if (!type) {
			let re_val = parseFloat(val);
			if (!isNaN(re_val)) {
				if (re_val > 10000) {
					return (re_val / 10000).toFixed(2) + '万';
				} else {
					return re_val;
				}
			} else {
				return val;
			}
		}
		return val;
	},
	//获取某月的第一天至最后一天
	//入参 yyyy-MM-dd
	getMonByDaySection(dateStr) {
		console.log("getMonByDaySection", dateStr);
		let dataArr = dateStr.split('-');
		let s_y = parseInt(dataArr[0]);
		let s_m = parseInt(dataArr[1]);
		// let s_d = parseInt(dataArr[2]);
		let date = new Date(s_y, s_m, 0);

		// console.log("date",date);

		var y = date.getFullYear(); //获取年份
		var m = date.getMonth() + 1; //获取月份
		var f_d = '01';
		m = m < 10 ? '0' + m : m; //月份补 0
		var l_d = date.getDate();
		l_d = l_d < 10 ? '0' + l_d : l_d; //日数补 0
		let FirstDay = [y, m, f_d].join("-");
		let LastDay = [y, m, l_d].join("-");
		let CurMon = [y, m].join("-");
		return [FirstDay, LastDay, CurMon];
	},

	//查询海柜路线
	toSerCabLine: (CabNo) => {
		let URL = `https://www.searates.com/container/tracking/?number=${CabNo}`;
		window.open(URL, '_blank')
	},

	//复制文本
	copyText: async (text) => {
		// try {
		//   await navigator.clipboard.writeText(text);
		//   console.log('Text copied to clipboard');
		// 	Message({
		// 		message:"复制成功",
		// 		type:"success"
		// 	});
		// } catch (err) {
		//   console.error('Failed to copy: ', err);
		// 	Message({
		// 		message:"复制失败",
		// 		type:"warning"
		// 	});
		// }

		try {
			console.log(!!navigator.clipboard);
			console.log(window.isSecureContext);
			if (navigator.clipboard && window.isSecureContext) {
				// navigator clipboard 向剪贴板写文本
				await navigator.clipboard.writeText(text);
				Message({
					message: "复制成功",
					type: "success"
				});
			} else {
				// 创建text area
				let textArea = document.createElement("textarea");
				textArea.value = text;
				// 使text area不在viewport，同时设置不可见
				textArea.style.position = "absolute";
				textArea.style.opacity = 0;
				textArea.style.left = "-999999px";
				textArea.style.top = "-999999px";
				document.body.appendChild(textArea);
				textArea.focus();
				textArea.select();
				await new Promise((res, rej) => {
					// 执行复制命令并移除文本框
					document.execCommand('copy') ? res() : rej();
					textArea.remove();
				});
				Message({
					message: "复制成功",
					type: "success",
					showClose: true,
				});
			}
		} catch (err) {
			console.error('Failed to copy: ', err);
			Message({
				message: "复制失败",
				type: "warning"
			});
		}
	},
	//校验国际区号和手机号
	// verifyAreaCodePhone(areacode,phone){
	//   if(null==areaCode||''==areaCode){
	//     this.$alert('请选择手机国际区号', this.$t('tips.tipsTitle'), {
	//       //
	//     });
	//     return false;
	//   }else if('86'===areacode && !this.$Validate.REGEX_PHONE.test(this.ruleForm.password)){
	//     this.$alert('请输入十一位手机号', this.$t('tips.tipsTitle'), {
	//       //
	//     });
	//     return false;
	//   }
	//   return true;
	// }

}

export default {
	install: function(Vue) {
		Object.defineProperty(Vue.prototype, '$Utils', {
			value: Utils
		});
	}
}